import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const npsSurveyBaseUrl = 'nps-surveys'

/**
 * @api GET /api/nps-surveys/form
 */
const getScore = () => {
	const url = `${npsSurveyBaseUrl}/score`
	return axios.get(url)
}

/**
 * @api GET /api/nps-surveys/score
 */
const setScore = state => {
	const url = `${npsSurveyBaseUrl}/score`
	return axios.post(url, { state: state })
}

export default {
	getScore: getScore,
	setScore: setScore
}
