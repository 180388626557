<template>
	<v-btn
		v-if="toDisplay && $vuetify.breakpoint.lgAndUp && !open && !sended"
		color="primary"
		small
		class="nps-survey-modal-button white--text mx-0 my-0"
		@click="open = true"
	>
		{{ hideMessage }}
		<v-icon right dark>assessment</v-icon>
	</v-btn>
	<div v-else-if="toDisplay && $vuetify.breakpoint.lgAndUp && open" class="nps-survey-modal">
		<div class="v-overlay v-overlay--active" @click="fx_animate"></div>
		<v-card class="px-2 py-2 elevation-10" :class="{ animating: animating }">
			<v-card-title class="py-0 px-2" style="position: absolute; right: 0; z-index: 1">
				<v-spacer></v-spacer>
				<v-tooltip bottom open-delay="500" z-index="1050">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" @click.prevent.stop="close">
							<v-icon>close</v-icon>
						</v-btn>
					</template>
					<span v-t="'nps_survey.dialog.actions.close'" />
				</v-tooltip>
			</v-card-title>
			<v-card-text>
				<w-layout v-if="loading" justify-center align-center row mt-2 pt-5 pb-4>
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
				</w-layout>
				<survey v-else :survey="surveyForm"></survey>
			</v-card-text>
		</v-card>
	</div>
	<WDialog
		v-else-if="toDisplay"
		v-model="open"
		eager
		persistent
		:max-width="$vuetify.breakpoint.xsOnly ? '90vw' : $vuetify.breakpoint.smOnly ? '70vw' : $vuetify.breakpoint.mdOnly ? '45vw' : '600px'"
	>
		<v-card elevation="0" class="px-0 py-0">
			<v-card-title class="py-0 px-0" style="position: absolute; right: 0; z-index: 1">
				<v-spacer></v-spacer>
				<template v-if="!isMobile()">
					<v-tooltip bottom open-delay="500" z-index="250">
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click.prevent.stop="close">
								<v-icon>close</v-icon>
							</v-btn>
						</template>
						<span v-t="'nps_survey.dialog.actions.close'" />
					</v-tooltip>
				</template>
				<template v-else>
					<v-btn icon @click.prevent.stop="close">
						<v-icon>close</v-icon>
					</v-btn>
				</template>
			</v-card-title>
			<v-card-text>
				<w-layout v-if="loading" justify-center align-center row mt-3 pt-5 pb-5>
					<v-progress-circular indeterminate></v-progress-circular>
				</w-layout>
				<survey v-else :survey="surveyForm"></survey>
			</v-card-text>
		</v-card>
	</WDialog>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import { StylesManager } from 'survey-core'
import AuthService from '@/services/Auth/AuthService'
import NPSSurveyService from '@/services/NPSSurvey/NPSSurveyService'
import { mapState } from 'vuex'
import '@/plugins/survey/design.css'

const LOCALSTORAGE_KEY = 'nps-survey-modal-hide'

let defaultThemeColors = StylesManager.ThemeColors['default']
defaultThemeColors['$main-color'] = 'var(--v-primary-base)'
defaultThemeColors['$main-hover-color'] = 'var(--v-primary-darken1)'
defaultThemeColors['$header-color'] = 'var(--v-primary-base)'
defaultThemeColors['$error-color'] = 'var(--v-error-base)'
defaultThemeColors['$progress-buttons-color'] = 'var(--v-secondary-lighten1)'
defaultThemeColors['$progress-buttons-line-color'] = 'var(--v-secondary-lighten3)'

defaultThemeColors['$header-background-color'] = 'transparent'
defaultThemeColors['$error-background-color'] = 'transparent'
defaultThemeColors['$body-container-background-color'] = 'transparent'
defaultThemeColors['$inputs-background-color'] = 'transparent'

if (window.vueInstance.$store.getters['user/getDarkModeState']) {
	defaultThemeColors['$text-color'] = 'white'
	defaultThemeColors['$text-input-color'] = 'white'
}

StylesManager.applyTheme()

let Survey = SurveyVue.Survey
Survey.cssType = 'default'

const STATES = {
	ACCEPTED: 'accepted',
	REJECTED: 'rejected'
}

const LOAD_WAITING_TIME_IN_SECS = 8

export default {
	name: 'SurveyModal',
	components: {
		survey: Survey
	},
	data: function () {
		return {
			animating: false,
			loading: true,
			toDisplay: false,
			open: false,
			sended: false,
			surveyForm: null,
			finalize: false,
			hideMessage: 'Enquête',
			rejectNumber: 2
		}
	},
	computed: {
		...mapState({
			userId: state => state.user?.id
		})
	},
	created: function () {
		setTimeout(() => {
			this.initializeStorage()
			this.load()
		}, LOAD_WAITING_TIME_IN_SECS * 1000)
	},
	methods: {
		fx_animate: function () {
			if (this.animating) {
				setTimeout(() => {
					this.fx_animate()
				}, 800)
			}
			this.animating = false
			setTimeout(() => {
				this.animating = true
			}, 10)
			setTimeout(() => {
				this.animating = false
			}, 800)
		},
		load: async function () {
			this.sended = false
			if (!AuthService.getIsAuthenticated()) {
				this.toDisplay = false
				return
			}
			let settings = await NPSSurveyService.getScore()
			if (settings === null || !settings.enable) {
				this.toDisplay = false
				return
			}
			this.hideMessage = settings.hide_message
			this.rejectNumber = parseInt(settings.reject_number)

			let storage = this.getStorage()
			if (storage.length >= this.rejectNumber && storage[storage.length - 1] !== this.storageEntryFormat(new Date())) {
				await this.send(STATES.REJECTED, true)
				this.clearStorage()
				return
			}

			if (Object.keys(settings.form).length <= 0) {
				this.toDisplay = false
				return
			}

			this.toDisplay = true
			this.loading = true
			this.open = this.getStorage().indexOf(this.storageEntryFormat(new Date())) === -1 || !this.$vuetify.breakpoint.lgAndUp
			settings.form.locale = this.$i18n.locale.split('-')[0] + ''.toLowerCase()

			this.surveyForm = new SurveyVue.Model(settings.form)
			this.surveyForm.setValue('domain', window.location.hostname)
			this.surveyForm.onComplete.add(async survey => {
				survey.setValue('user_id', this.userId)
				await this.send(STATES.ACCEPTED)
				survey.sendResult(settings.online_result_collect_id)
			})
			this.loading = false
		},
		/**
		 * @private
		 * @return {array}
		 */
		getStorage: function () {
			let data = localStorage.getItem(LOCALSTORAGE_KEY)

			if (data !== null) {
				try {
					data = Object.values(JSON.parse(data))
				} catch (e) {
					data = []
				}
			} else {
				data = []
			}

			return data
		},
		/**
		 * @private
		 * @param data
		 */
		setStorage: function (data) {
			localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data))
		},
		/**
		 * @private
		 */
		initializeStorage: function () {
			try {
				let data = localStorage.getItem(LOCALSTORAGE_KEY)

				if (!data || !Array.isArray(JSON.parse(data))) {
					this.setStorage([])
				}
			} catch (e) {
				this.setStorage([])
			}
		},
		/**
		 * @private
		 */
		clearStorage: function () {
			this.setStorage([])
		},
		/**
		 * @private
		 * @param {Date} date
		 */
		storageEntryFormat: function (date) {
			return date.getFullYear() + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getDate()).padStart(2, '0')
		},
		/**
		 * @private
		 */
		addStorageEntry: function () {
			let entry = this.storageEntryFormat(new Date())
			let storage = this.getStorage()
			if (storage.indexOf(entry) === -1) {
				storage.push(entry)
				this.setStorage(storage)
			}
		},
		/**
		 * Close NPS Form Modal and definitively until next satisfaction survey
		 */
		close: async function () {
			if (!this.sended) {
				this.addStorageEntry()
			}

			this.open = false
		},
		/**
		 * Send user NPS Survey response
		 * @param {string} state
		 * @param {boolean=} dismissModalDirectly
		 * @return {Promise<void>}
		 */
		send: async function (state, dismissModalDirectly) {
			let toDisplayBeforeSend = this.toDisplay

			if (dismissModalDirectly) {
				this.toDisplay = false
				this.open = false
			}

			this.loading = true
			await NPSSurveyService.setScore(state)
			this.sended = true
			this.loading = false

			this.toDisplay = toDisplayBeforeSend
		}
	}
}
</script>

<style scoped>
.nps-survey-modal-button {
	position: fixed;
	bottom: 0;
	right: 0;
}

.nps-survey-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	align-items: flex-end;
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.nps-survey-modal .v-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.nps-survey-modal .v-card,
.nps-survey-modal-button {
	margin-right: 2vw !important;
}

.nps-survey-modal .v-card {
	z-index: 210;
	max-height: 600px;
	overflow-y: auto;
	width: 650px;
}

.nps-survey-modal .v-card.animating {
	animation-duration: 0.15s;
	animation-name: animate-dialog;
	animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>
