// ============================================================================
// NPSSurveyService
// ----------------
// NPSSurvey module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/NPSSurveyApi'

// -------
// Exports
// -------
/**
 * Export Module Api
 */
export default {
	getScore: function () {
		return API.getScore().then(res => res.data.data)
	},
	setScore: function (state) {
		return API.setScore(state).then(res => res.data.data)
	}
}
